.button {
  padding: 20px 45px;
  font-size: 32px;
  color: white;
  background-color: #ffaf03 !important;
  &:hover {
    background-color: #ffbe33 !important;
    border-color: #ffbe33 !important;
    color: white;
  }
}

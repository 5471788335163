.requestCallBack {
  margin: 100px 0 0 0;
  padding: 70px 0px;
  background-color: rgb(24, 54, 80);
  color: white;
  .ctaBlock {
    padding-right: 80px;
    p {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 22px;
      word-break: normal;
    }
  }
  .formBlock {
    font-family: "Open Sans", sans-serif;
    input[type="text"] {
      width: 100%;
    }
    select {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
    }
    input[type="submit"],
    p {
      font-family: "Poppins";
    }
  }
}

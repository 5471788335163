.testimonials {
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .testimonialText {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    position: relative;
    max-width: 600px;
    padding: 30px;
    text-align: justify;
    &::before {
      content: "";
      background-image: url(../../../images/Testimonial-Quotes.svg);
      width: 37px;
      height: 37px;
      position: absolute;
      left: -70px;
      top: -18px;
    }
  }
  .testimonialClient {
    font-size: 20px;
    color: rgb(24, 54, 80);
  }
}

.contentSection {
  margin-top: 100px;
  .content {
    justify-content: center;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -2.7px;
    color: rgb(0, 46, 91);
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 22px;
  }
  .statsbanner {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.1),
    //     rgba(255, 255, 255, 0.1)
    //   ),
    //   url("../../images/banner_background.gif");
    background-image: url("../../images/banner_background.gif");
    background-size: cover;
    // transition: opacity 0.5s ease;
    // background-color: rgb(24, 54, 80);
  }
  .info {
    width: 75%;
  }
}
.indicationButton {
  margin-top: 100px;
  text-align: center;
}

.services {
  margin: 100px 0 0 0;
  h2 {
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -2.7px;
    color: #002e5b;
    margin-bottom: 30px;
  }
  .serviceList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    .service {
      max-width: 30%;
      // min-width: 30%;
      display: flex;
      // flex-direction: column;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 30px;
      .info {
        font-family: "Open Sans", sans-serif;
        margin-left: 0.5rem;
        .description {
          font-size: 14px;
        }
      }
      .image {
        padding-top: 3px;
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
        margin-bottom: 20px;
      }
      h5 {
        text-align: left;
        font-size: 18px;
        font-weight: 700;
        color: #002e5b;
      }
      @media (max-width: 768px) {
        min-width: 45%;
      }
      @media (max-width: 576px) {
        min-width: 90%;
      }
    }
  }
}

.newrates-container {
	height: 100vh;
	padding: 15px;
}

.newrates-bg {
	background: #e5f1fd;
}

.header-container {
	grid-gap: 1rem;
}

.rates-container {
	row-gap: 1rem;
}

.col-22 {
	flex: 0 0 22%;
	max-width: 22%;
}

.rate-box {
	min-height: 38px;
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	outline: 0 !important;
	box-sizing: border-box;
}

.fs-4 {
	font-size: 1.5rem !important;
}

.fs-5 {
	font-size: 1.25rem !important;
}

.fs-6 {
	font-size: 1rem !important;
}

.fs-7 {
	font-size: 0.75rem !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.newrates-img {
	cursor: pointer;
}

.delete-row {
	padding-bottom: 8px;
}

.workCompCode {
	div[id^="react-select-"][id$="-listbox"] {
		width: 85vw;
	}
}

.react-datepicker__input-container {
	border-radius: 2px;
	border-width: 0;
	box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
	border-color: hsl(0, 0%, 80%);
	outline: currentcolor none medium !important;
}

@media all and (min-width: 576px) and (max-width: 993px) {
	.col-sm-6{
		flex: 0 0 60%;
		max-width: 60%;
		padding-left:0px !important;
	}
  }
  @media all and (min-width: 994px) and (max-width: 1170px) {
	.col-sm-6{
		flex: 0 0 55%;
		max-width: 55%;
		padding-left:0px !important;
	}
  }
.css-1r4vtzz {
	height: 35px;
	padding: 0.5rem;
}

.divider {
	height: 0;
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.bg-white {
	background-color: white;
}

.dv-rates {
	max-width: 280px;
	min-width: 280px;
}

@media screen and (max-width: 900px) {
	.delete-row{
		margin-top: 17px;
	}
}
.landingFooter {
  padding: 50px 0;
  background-color: #0e1c27;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  .footerContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 40px;
  }
}

.statsBanner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  // gap: 190px;
  padding: 50px 0px;
  width: 75%;
  color: white;
  .statsBannerItem {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    line-height: 1 !important;
    h3 {
      font-size: 60px;
      font-weight: 700;
    }
    h5 {
      font-size: 14px;
      line-height: 1.2 !important;
    }
  }
  @media screen and (max-width: 1024px) {
    .statsBannerItem {
      h3 {
        font-size: 50px;
      }
      h5 {
        font-size: 11.7px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .statsBannerItem {
      h3 {
        font-size: 40px;
      }
      h5 {
        font-size: 9.4px;
      }
    }
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
}

.imageBlock {
  .headingColumn {
    background-color: #183650;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 60px;
    h1 {
      font-size: 60px;
      text-align: center;
      //   letter-spacing: -1px;
    }
    p {
      font-size: 20px;
      text-align: center;
      margin-top: 20px;
      font-family: "Open Sans";
      line-height: 1.5;
      font-weight: 400;
    }
  }
  .imageColumn {
    max-height: 400px;
    min-height: 400px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // object-position: right center;
    }
  }
  @media screen and (max-width: 700px) {
    .imageColumn {
      display: none;
    }
    .headingColumn {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
